import imagesLoaded from 'imagesloaded';

$(function () {
    'use strict';

    // detectOldBrowser();

    var Utilities = {
        events: [],
        assign: function (target, source) {
            var result = {};

            for (var i in target) result[i] = target[i];
            for (var i in source) result[i] = source[i];

            return result;
        },
        scrollToTarget: function (selector, offsetY = 0) {
            const triggers = document.querySelectorAll(selector);

            triggers.forEach((el) => {
                el.addEventListener('click', (e) => {
                    e.preventDefault();

                    // Get the target elements
                    const parent = el.parentNode,
                        targetElOffsetTop = parent.offsetTop + parent.offsetHeight;

                    window.scroll({
                        top: targetElOffsetTop - offsetY,
                        left: 0,
                        behavior: 'smooth',
                    });
                });
            });
        },
        addEvent: function (event, el, callback) {
            let i = 0,
                j = 0,
                addNode = true,
                addEvent = true;

            for (i; i < Utilities.events.length; i++) {
                // Check for presence of this element already
                if (Utilities.events[i].node === el) {
                    addNode = false;

                    // Already setup. Do we already have this event too?
                    for (j; j < Utilities.events[i].listeners; j++) {
                        if (Utilities.events[i].listeners[j].type === event) {
                            addEvent = false;
                        }
                    }

                    // If addEvent is still true by here we need to add it to the
                    // existing node object...
                    Utilities.events[i].listeners.push({
                        type: event,
                        func: callback.toString(),
                    });
                }
            }

            if (addNode) {
                // Record the node and event so we can look it up later if necessary
                Utilities.events.push({
                    node: el,
                    listeners: [
                        {
                            type: event,
                            func: callback.toString(),
                        },
                    ],
                });
            }

            // Attach the event
            el.addEventListener(event, callback);
        },

        forEach: function (array, callback, scope) {
            for (var i = 0; i < array.length; i++) {
                callback.call(scope, array[i], i);
            }
        },
    };

    var Cookies = {
        init: function () {
            const container = document.querySelector('.js-cookies-notice'),
                close = document.querySelector('.js-cookies-notice-close');

            // Do we have a cookie set to prevent this notice showing?
            if (
                !document.cookie.split(';').filter(function (item) {
                    return item.indexOf('cookiesnotice=') >= 0;
                }).length
            ) {
                // Cookie doesn't exist
                // Show the notice
                container.classList.add('show');

                // Clicking on the close link hides the cookies notice and sets a cookie to cause it not to show in the future
                close.addEventListener('click', () => {
                    var d = new Date();

                    // Hide notice
                    container.classList.remove('show');

                    d.setTime(d.getTime() + 182.5 * 24 * 60 * 60 * 1000); // ~ 6 months from now

                    // Set the cookie
                    document.cookie = 'cookiesnotice=1; expires=' + d.toUTCString() + '; path=/';
                });
            }
        },
    };

    function newPageLoad(e) {
        // CLose modals
        globalHeader.closeMailchimp();

        // If the user is trying to open this in a new tab / window, don't get in the way!
        if (e.ctrlKey || e.shiftKey || e.metaKey || (e.button && e.button === 1)) {
            return;
        }

        var $link = $(this);

        var $target = $(e.currentTarget),
            href = $target.attr('href'),
            check = window.location.href;

        // If this is a close layer link
        if ($target.hasClass('js-close-page')) {
            // If there is only one layer, this should work statically
            if (pageHandler.pages.length < 2) {
                return;
            }

            e.preventDefault();

            // Go back
            window.history.back();

            // Don't go on
            return;
        }

        // If this is an internal link
        if (window.location.hostname === this.hostname) {
            e.preventDefault();

            // If we're already on this page, do nothing
            if (href == check) {
                return;
            }

            // Go!
            pageHandler.pageReloader(href, $link);
        }
    }

    function AjaxWorker() {}

    AjaxWorker.prototype.getAjaxUrl = function () {
        return this.$ajaxContainer.data('ajax-url');
    };

    /*
		Generates the post data for an ajax request from the data on the ajax container
	*/
    AjaxWorker.prototype.getPostDataFromContainer = function () {
        var postData = {},
            entryId = this.$ajaxContainer.data('entry-id'),
            categoryId = this.$ajaxContainer.data('category-id'),
            loadLimit = this.$ajaxContainer.data('load-limit'),
            featured = this.$ajaxContainer.data('featured'),
            omit = this.$ajaxContainer.data('omit');

        if (entryId) {
            postData.entryId = entryId;
        }

        if (featured) {
            postData.featured = featured;
        }

        if (categoryId) {
            postData.categoryId = categoryId;
        }

        if (omit) {
            postData.omit = omit;
        }

        if (loadLimit) {
            postData.loadLimit = loadLimit;
        }

        return postData;
    };

    AjaxWorker.prototype.parseReturnData = function (returnData) {
        var $returnData = $(returnData),
            $metas = $returnData.filter('.js-meta'),
            meta = {};

        // Work through each of the meta tags
        $metas.each(function () {
            var $meta = $(this);

            // Assign the meta data to the meta object
            meta[$meta.data('meta-key')] = $meta.data('meta-value');
        });

        return {
            // content is everything bar the $metas
            $content: $returnData.not($metas),
            meta: meta,
        };
    };

    function Easer($el) {
        this.$el = $el;
    }

    Easer.prototype.easeInClass = 'ease-in';

    Easer.prototype.calcTarget = function () {
        // The point at which this element becomes 'on screen'
        this.target = this.$el.offset().top - winH;
    };

    /*
		Returns true if the scroll position is correct for this
	*/
    Easer.prototype.readyToReveal = function () {
        return winScrollT >= this.target;
    };

    Easer.prototype.reveal = function (lastRevealTime) {
        var delay = 0;

        // If we are very close to the last reveal
        if (Date.now() - lastRevealTime < 500) {
            // Add a bit of a stagger (100 - 400ms at time of writing)
            delay = Math.max(Math.random() * 400, 100);
        }

        setTimeout(
            function () {
                this.$el.addClass(this.easeInClass);
            }.bind(this),
            delay
        );
    };

    function EasersHandler($initialEasers, $layer) {
        this.$layer = $layer;
        this.easers = [];
        this.lastRevealTime = 0;

        this.createEasers($initialEasers);
    }

    EasersHandler.prototype.easerClass = 'js-easer';

    EasersHandler.prototype.createEasers = function ($easers) {
        // If we weren't passed any
        if (!$easers) {
            // Get every easer in this layer
            $easers = this.$layer.find('.' + this.easerClass);
        }

        $easers.each(
            function (i) {
                this.easers.push(new Easer($easers.eq(i)));
            }.bind(this)
        );

        this.calcAndCheck();
    };

    EasersHandler.prototype.onScroll = function () {
        this.checkForReveal();
    };

    EasersHandler.prototype.onResize = function () {
        this.calcAndCheck();
    };

    EasersHandler.prototype.onElsAppended = function ($els) {
        // Get any children or any elements part of $els
        this.createEasers($els.find('.' + this.easerClass).addBack('.' + this.easerClass));

        this.calcAndCheck();
    };

    EasersHandler.prototype.onReset = function () {
        // Delete the array
        this.easers.length = 0;

        this.createEasers();

        this.calcAndCheck();
    };

    EasersHandler.prototype.onBeforeShow = function () {
        this.calcAndCheck();
    };

    EasersHandler.prototype.calcAndCheck = function () {
        this.calcTargets();

        this.checkForReveal();
    };

    EasersHandler.prototype.calcTargets = function () {
        this.easers.forEach(function (easer) {
            easer.calcTarget();
        });
    };

    EasersHandler.prototype.checkForReveal = function () {
        var easer;

        for (var i = 0; i < this.easers.length; i++) {
            easer = this.easers[i];

            if (easer.readyToReveal()) {
                easer.reveal(this.lastRevealTime);

                this.lastRevealTime = Date.now();

                // Remove this item from the array and reduce i to compensate
                this.easers.splice(i, 1);
                i--;
            }
        }
    };

    function InfScrollHandler($container) {
        this.$container = $container;
        this.$ajaxContainer = this.$container;

        this.begin();
    }

    InfScrollHandler.prototype = new AjaxWorker();

    InfScrollHandler.prototype.containerClass = 'js-inf-scroll';

    InfScrollHandler.prototype.begin = function () {
        this.complete = false;

        this.total = this.$container.data('total');

        if (this.gotAll()) {
            this.onComplete();
        } else {
            this.calcAndCheck();
        }
    };

    InfScrollHandler.prototype.onScroll = function () {
        this.checkForLoad();
    };

    InfScrollHandler.prototype.onResize = function () {
        this.calcAndCheck();
    };

    InfScrollHandler.prototype.onReset = function () {
        this.begin();
    };

    InfScrollHandler.prototype.calcAndCheck = function () {
        this.calcTarget();

        this.checkForLoad();
    };

    InfScrollHandler.prototype.calcTarget = function () {
        if (this.complete) {
            return;
        }

        // Half the winH before we're right at the bottom of the container
        this.scrollTarget = Math.round(
            this.$container.offset().top + this.$container.innerHeight() - winH * 2
        );
    };

    InfScrollHandler.prototype.checkForLoad = function () {
        if (this.complete || this.loading) {
            return;
        }

        // If we are at the target point
        if (winScrollT >= this.scrollTarget) {
            this.load();
        }
    };

    InfScrollHandler.prototype.load = function () {
        var postData = this.getPostDataFromContainer(),
            offset = this.getCurrentItemsLength();

        postData[csrfTokenName] = csrfTokenValue;
        postData.offset = offset;

        // Set a flag to say that we are loading
        this.loading = true;

        // Post to the relevant url
        this.xhr = $.post(this.getAjaxUrl(), postData, this.handleReturnData.bind(this));
    };

    InfScrollHandler.prototype.handleReturnData = function (returnData) {
        var $content = this.parseReturnData(returnData).$content;

        // Add the new content to the container
        this.$container.append($content);

        pageHandler.onElsAppended($content, this);

        // Update the complete flag
        if (this.gotAll()) {
            this.onComplete();
        }

        this.loading = false;

        this.calcAndCheck();
    };

    InfScrollHandler.prototype.gotAll = function () {
        // Return a boolean which checks whether the current number of items we have
        // is greater or equal than the total entries
        return this.getCurrentItemsLength() >= this.total;
    };

    InfScrollHandler.prototype.getCurrentItemsLength = function () {
        // Return the current number of items we have
        var children = this.$container.find('.js-inf-item');
        return children.length;
    };

    InfScrollHandler.prototype.onComplete = function () {
        this.setCompleteFlagToTrue();

        this.$container.closest('.js-page-content').addClass('inf-scroll-complete');
    };

    InfScrollHandler.prototype.setCompleteFlagToTrue = function () {
        this.complete = true;
    };

    InfScrollHandler.prototype.abort = function () {
        // If an xhr has begun (or had begun previously)
        if (typeof this.xhr === 'object') {
            this.xhr.abort();
        }

        this.loading = false;
    };

    function InlineCarouselHandler($container) {
        this.inlineCarousels = [];
        this.$container = $container;

        this.setUpCarousels($container);
    }

    InlineCarouselHandler.prototype.inlineCarouselClass = 'js-carousel';

    InlineCarouselHandler.prototype.heroCarouselClass = 'carousel__container--hero';

    InlineCarouselHandler.prototype.setUpCarousels = function ($els) {
        var inlineCarousels = [],
            $container = this.$container;

        // For each inline video element
        $els.find('.js-carousel').each(function () {
            // Create a new inline video
            inlineCarousels.push(new CarouselHandler($(this)));
        });

        // For each of them
        for (var i = 0; i < inlineCarousels.length; i++) {
            // Assign them to this layer's handlers array
            this.inlineCarousels.push(inlineCarousels[i]);
        }
    };

    InlineCarouselHandler.prototype.onElsAppended = function ($els) {
        this.setUpCarousels($els);
    };

    InlineCarouselHandler.prototype.pauseAllCarousels = function () {
        this.inlineCarousels.forEach(function (carousel) {
            carousel.breakTimer();
        });
    };

    InlineCarouselHandler.prototype.playAllCarousels = function () {
        this.inlineCarousels.forEach(function (carousel) {
            carousel.startTimer();
        });
    };

    InlineCarouselHandler.prototype.onResize = function () {
        this.inlineCarousels.forEach(function (carousel) {
            // Individual item calculations are handled at the item level
            carousel.onResize();
        });
    };

    InlineCarouselHandler.prototype.onBeforeSuperseded = function () {
        this.inlineCarousels.forEach(function (carousel) {
            // Individual item calculations are handled at the item level
            carousel.onBeforeSuperseded();
        });
    };

    InlineCarouselHandler.prototype.onBeforeClose = function () {
        this.inlineCarousels.forEach(function (carousel) {
            // Individual item calculations are handled at the item level
            carousel.onBeforeClose();
        });
    };

    InlineCarouselHandler.prototype.onBeforeShow = function () {
        this.inlineCarousels.forEach(function (carousel) {
            // Individual item calculations are handled at the item level
            carousel.onBeforeShow();
        });
    };

    // The Carousel class.
    function CarouselHandler(el) {
        this.DOM = { el: el };
        this.$el = el;
        this.mobile = null;

        this.arrows = [];
        Array.from(this.DOM.el.find('.js-carousel-swipe')).forEach((arrow) =>
            this.arrows.push(arrow)
        );

        // Navigation controls (prev, next and preview ctrls)
        this.controls = [];
        Array.from(this.DOM.el.find('.carousel-nav__item')).forEach((controlEl) =>
            this.controls.push(controlEl)
        );

        // The slides.
        this.slides = [];
        Array.from(this.DOM.el.find('.carousel-slide')).forEach((slideEl) =>
            this.slides.push(new Slide(slideEl))
        );

        // The total number of slides.
        this.slidesTotal = this.slides.length;

        // Current slide position.
        this.current = 0;
        this.previous = 0;

        // Get carousel class to define behaviour
        this.determineCarouselClass();

        this.initTouchEvents();
        this.initTimer();
        this.classSpecificSettings();
        this.setDevice();
        this.init();
    }

    CarouselHandler.prototype.carouselClass = 'js-carousel';

    // init: set the current slide and initialize some events..
    CarouselHandler.prototype.init = function () {
        this.initEvents();

        this.setCurrentHeight();

        // When images are loaded, show first slide.
        imagesLoaded(this.$el, () => {
            this.slides[0].show('left');
        });
    };

    CarouselHandler.prototype.initTimer = function () {
        this.delay = this.$el.data('delay') * 1000;

        if (this.delay) {
            this.timerActive = true;
        }
    };

    CarouselHandler.prototype.startTimer = function () {
        const $slider = this;
        
        // If there are multiple slides and timer class is active
        if (this.slides.length > 1 && this.timerActive) {
            // Replace existing timer, if exists
            if (this.delayActive) {
                clearTimeout(this.restart);
            }

            // Register that timer is waiting to go
            this.delayActive = true;

            // Get delay (if applicable)
            const delayStart = this.delay == null ? 0 : this.delay;

            // Restart timer
            if (this.delay) {
                this.restart = setTimeout(
                    function () {
                        // Set timer running
                        this.timerInterval = setInterval(function () {
                            if (!$slider.paused) {
                                $slider.swipe('right', false);
                            }
                        }, this.delay);

                        // Remove block
                        this.delayActive = false;
                    }.bind(this),
                    delayStart
                );
            }
        }
    };

    CarouselHandler.prototype.breakTimer = function () {
        if (this.slides.length > 1 && this.timerActive) {
            clearInterval(this.timerInterval);
        }
    };

    CarouselHandler.prototype.initTouchEvents = function () {
        var xDown = null,
            yDown = null,
            moving = false;

        // Listen for trackpad scroll
        this.$el[0].addEventListener(
            'wheel',
            function (e) {
                if (moving == true) {
                    return;
                }
                const deltaX = Math.max(-10, Math.min(10, e.deltaX));
                const deltaY = Math.max(-10, Math.min(10, e.deltaY));

                if (Math.abs(deltaX) > Math.abs(deltaY)) {
                    e.preventDefault();
                    if (deltaX > 5) {
                        moving = true;
                        this.swipe('right', true);
                    }
                    if (deltaX < -5) {
                        moving = true;
                        this.swipe('left', true);
                    }
                }
                window.setTimeout(function () {
                    moving = false;
                }, 1500);
            }.bind(this)
        );

        // Listen for swipe events
        this.$el.on(
            'touchstart',
            function (e) {
                xDown = e.originalEvent.touches[0].clientX;
                yDown = e.originalEvent.touches[0].clientY;
            }.bind(this),
            { passive: true }
        );
        this.$el.on(
            'touchmove',
            function (e) {
                if (!xDown) {
                    return;
                }

                var xUp = e.touches[0].clientX;
                var yUp = e.touches[0].clientY;

                var xDiff = xDown - xUp;
                var yDiff = yDown - yUp;

                // If this is a horizontal swipe, not vertical
                if (Math.abs(xDiff) > Math.abs(yDiff)) {
                    // Minimum size of swipe
                    if (Math.abs(xDiff) > 5) {
                        // Test whether left or right
                        if (xDiff > 0) {
                            this.swipe('right', true);
                        } else {
                            this.swipe('left', true);
                        }
                    }
                }

                /* reset values */
                xDown = null;
                yDown = null;
            }.bind(this),
            { passive: true }
        );

        // Listen for keypad
        $win.on(
            'keydown',
            function (e) {
                if (this.isInViewport(this.$el[0])) {
                    switch (event.keyCode) {
                        case 37:
                            this.swipe('left', true);
                            break;
                        case 39:
                            this.swipe('right', true);
                            break;
                    }
                }
            }.bind(this)
        );
    };

    CarouselHandler.prototype.isInViewport = function (elem) {
        // Get position in the viewport
        var bounding = elem.getBoundingClientRect();

        return (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    CarouselHandler.prototype.determineCarouselClass = function () {
        // Hero images on Feature Pages
        if (this.$el[0].classList.contains('carousel__container--hero')) {
            this.isHero = true;
            this.carouselClass = 'hero';
        }

        // Mid-page carousel with text and multiple images
        else if (this.$el[0].classList.contains('big-carousel')) {
            this.isBigCarousel = true;
            this.carouselClass = 'big';
        }

        // Special behaviour for the home carousel
        else if (this.$el[0].classList.contains('js-home-carousel')) {
            this.isHomeCarousel = true;
            this.carouselClass = 'home';
        }

        // Class for mini carousels within article lists
        else {
            this.carouselClass = 'postfeed';
        }

        // Set the class to all the child Slides
        for (var i = 0; i < this.slides.length; i++) {
            this.slides[i].setSlideClass(this.carouselClass);
        }
    };

    CarouselHandler.prototype.classSpecificSettings = function () {
        // All carousels with fullwidth background images
        if (!this.isBigCarousel) {
            this.backgrounds = [];

            // Find all the backgrounds
            Array.from(this.DOM.el.find('.slide__background')).forEach((background) =>
                this.backgrounds.push(background)
            );

            // Then attach background to relevant slide
            for (var i = 0; i < this.backgrounds.length; i++) {
                this.slides[i].DOM.imgBackground = this.backgrounds[i];
            }
        }

        // For homepage carousel, get the colour overlays
        if (this.isHomeCarousel) {
            this.overlays = [];

            Array.from(this.DOM.el.find('.homepage__slide-overlay')).forEach((overlay) =>
                this.overlays.push(overlay)
            );

            // Then attach overlay to relevant slide
            for (var i = 0; i < this.overlays.length; i++) {
                this.slides[i].DOM.overlay = this.overlays[i];
            }
        }

        // If necessary, find the tallest slide to set the carousel height
        if (this.isHero || this.isBigCarousel) {
            this.biggestHeight = this.determineBiggestHeight();
        }
    };

    CarouselHandler.prototype.setDevice = function () {
        // If we are on mobile version
        if (globalHeader.determineMobile()) {
            // If mobile has not already been set
            // (Avoid firing on every small resize)
            if (this.mobile != true) {
                this.mobile = true;
                for (var i = 0; i < this.slides.length; i++) {
                    this.slides[i].setDevice(this.mobile);
                }
            }

            // For desktop
        } else {
            if (this.mobile != false) {
                this.mobile = false;
                for (var i = 0; i < this.slides.length; i++) {
                    this.slides[i].setDevice(this.mobile);
                }
            }
        }
    };

    CarouselHandler.prototype.initEvents = function () {
        this.controls.forEach(this.addEventListener, this);
        if (this.arrows.length > 0) {
            this.arrows.forEach(this.addArrowListener, this);
        }
    };

    CarouselHandler.prototype.determineBiggestHeight = function () {
        var slideHeights = [];
        for (var i = 0; i < this.slides.length; i++) {
            slideHeights.push(this.slides[i].DOM.el.clientHeight);
        }

        return Math.max.apply(Math, slideHeights);
    };

    CarouselHandler.prototype.setCurrentHeight = function () {
        // Postfeed carousel height is determined by flexbox etc
        if (this.carouselClass !== 'postfeed') {
            // If Big carousel or Hero, default to largest height
            if (this.isBigCarousel || this.isHero) {
                var slideHeight = this.biggestHeight;
            } else {
                var currentSlide = this.slides[this.current].DOM.el,
                    slideHeight = currentSlide.clientHeight;
            }

            // Set height
            this.$el[0].style.height = slideHeight + 'px';
        }
    };

    CarouselHandler.prototype.addEventListener = function (control, index) {
        control.addEventListener(
            'click',
            function () {
                this.slideMove(index);
            }.bind(this)
        );
    };

    CarouselHandler.prototype.addArrowListener = function (arrow) {
        const direction = arrow.dataset.direction;
        arrow.addEventListener(
            'click',
            function () {
                this.swipe(direction, true);
            }.bind(this)
        );
    };

    CarouselHandler.prototype.slideMove = function (number) {
        const nextSlidePos = number;

        // Don't move if this is current slide
        if (this.current === number) {
            return;
        }

        // Determine direction for animation
        const direction = this.current < number ? 'right' : 'left';

        this.swipe(direction, false, number);
    };

    // swipe the slideshow.
    CarouselHandler.prototype.swipe = function (direction, manual, jump) {
        // If animating return.
        if (this.isAnimating) return;
        this.isAnimating = true;

        this.isPaused = false;

        var nextSlidePos, transformDirection;

        // If we have a specific slide number, use that
        if (jump != null) {
            nextSlidePos = jump;

            // Otherwise, determine the next slide
        } else {
            nextSlidePos =
                direction === 'right'
                    ? this.current < this.slidesTotal - 1
                        ? this.current + 1
                        : 0
                    : this.current > 0
                    ? this.current - 1
                    : this.slidesTotal - 1;
        }

        // Switch the current slide control
        // if (!this.isHomeCarousel) {
            this.controls[this.current].classList.remove('current');
            this.controls[nextSlidePos].classList.add('current');
        // }

        // Reset the 'previous' element
        if (!this.isBigCarousel) {
            this.backgrounds[this.previous].classList.remove('previous');
        }

        // Set current to previous (for animation purposes)
        this.previous = this.current;
        this.current = nextSlidePos;

        // Switch the class states on the backgrounds
        if (!this.isBigCarousel) {
            this.backgrounds[this.previous].classList.remove('current');
            this.backgrounds[this.previous].classList.add('previous');
            this.backgrounds[nextSlidePos].classList.add('current');
        }

        // this.slides[this.previous].setCurrent(false);
        // this.slides[this.current].setCurrent();

        // Move the slides
        Promise.all([
            this.slides[this.previous].hide(direction),
            this.slides[this.current].show(direction),
        ]).then(() => {
            // Establish new current slide
            this.slides[this.previous].setCurrent(false);
            this.slides[this.current].setCurrent();
            this.isAnimating = false;
        });
    };

    CarouselHandler.prototype.onResize = function () {
        // Check for mobile
        this.setDevice();

        // Resize carousel height
        if (this.isBigCarousel || this.isHero) {
            this.biggestHeight = this.determineBiggestHeight();
        }
        this.setCurrentHeight();
    };

    CarouselHandler.prototype.onBeforeSuperseded = function () {
        if (this.isHomeCarousel) {
            this.breakTimer();
        }
    };

    CarouselHandler.prototype.onBeforeClose = function () {
        if (this.isHomeCarousel) {
            this.breakTimer();
        }
    };

    CarouselHandler.prototype.onBeforeShow = function () {
        if (this.isHomeCarousel) {
            this.startTimer();
        }
    };

    // The Slide class.
    function Slide(el) {
        this.DOM = { el: el };
        this.mobile = false;

        // The slide´s number element
        this.DOM.number = this.DOM.el.querySelector('.slide__number');

        // Some config values.
        this.config = {
            animation: {
                duration: 1,
                ease: Circ.easeInOut,
            },
        };

        // Values that depend on the device size
        this.deviceSpecific = {
            bigImgY: '-50%',
        };
    }

    Slide.prototype.setDevice = function (mobile) {
        if (mobile) {
            this.mobile = true;
        } else {
            this.mobile = false;
        }

        // Only applies to the mid-page, multi-image carousels
        if (this.slideClass == 'big') {
            if (mobile) {
                this.deviceSpecific.bigImgY = '0%';
            } else {
                this.deviceSpecific.bigImgY = '-50%';
            }

            // Fix the Y positioning
            let imgWrapOpts = Utilities.assign({}, { delay: 0, y: this.deviceSpecific.bigImgY });
            TweenMax.to(this.DOM.imgWrap, 0, imgWrapOpts);
        }
    };

    Slide.prototype.setSlideClass = function (slideClass) {
        this.slideClass = slideClass;
        this.findNewSlideObjects(slideClass);
    };

    Slide.prototype.findNewSlideObjects = function (slideClass) {
        // Find elements needed for the slide transition.
        if (slideClass == 'big') {
            this.DOM.imgWrap = this.DOM.el.querySelector('.big-slide__images');
            this.DOM.textWrap = this.DOM.el.querySelector('.big-slide__text');
            this.DOM.title = this.DOM.el.querySelector('.big-slide__title');
            this.DOM.firstImg = this.DOM.el.querySelector('.big-slide__image.first');
        }

        if (slideClass == 'hero') {
            this.DOM.link = this.DOM.el.querySelector('.js-link-container');
            this.DOM.textWrap = this.DOM.el.querySelector('.slide__text');
        }

        if (slideClass == 'home') {
            this.DOM.title = this.DOM.el.querySelector('.homepage__slide-content');
        }

        if (slideClass == 'postfeed') {
            this.DOM.link = this.DOM.el.querySelector('.article-link__category');
            this.DOM.title = this.DOM.el.querySelector('.article-carousel__item-title');
        }
    };

    // Sets the current class.
    Slide.prototype.setCurrent = function (isCurrent = true) {
        this.DOM.el.classList[isCurrent ? 'add' : 'remove']('current');
    };

    // Hide the slide.
    Slide.prototype.hide = function (direction) {
        if (this.slideClass == 'big') {
            return this.bigTransition('hide', direction);
        }

        if (this.slideClass == 'hero') {
            return this.heroTransition('hide', direction);
        }

        if (this.slideClass == 'home') {
            return this.homeTransition('hide', direction);
        }

        // Regular postfeed carousel
        return this.toggle('hide', direction);
    };

    // Show the slide.
    Slide.prototype.show = function (direction) {
        if (this.slideClass == 'big') {
            return this.bigTransition('show', direction);
        }

        if (this.slideClass == 'hero') {
            return this.heroTransition('show', direction);
        }

        if (this.slideClass == 'home') {
            return this.homeTransition('show', direction);
        }

        return this.toggle('show', direction);
    };

    // Show/Hide the basic slide.
    Slide.prototype.toggle = function (action, direction) {
        return new Promise((resolve, reject) => {
            const commonOpts = {
                delay: 0,
                ease: Expo.EaseInOut,
                onComplete: resolve,
            };

            let duration = 0.6;

            let textDuration = action === 'show' ? duration : duration / 2;

            let imgOpts = Utilities.assign({}, commonOpts);
            let textOpts = Utilities.assign({}, commonOpts);

            imgOpts.startAt =
                action === 'show' ? { x: direction === 'left' ? '-100%' : '100%' } : { x: '0%' };
            imgOpts.x = action === 'hide' ? (direction === 'left' ? '20%' : '-20%') : '0%';

            textOpts.startAt =
                action === 'show'
                    ? { opacity: 0, x: direction === 'left' ? '-10%' : '10%' }
                    : { x: '0%' };
            textOpts.opacity = action === 'hide' ? 0 : 1;
            textOpts.x = '0%';
            textOpts.delay = action === 'hide' ? 0 : duration / 3;

            // Moving the content
            TweenMax.to(this.DOM.imgBackground, duration, imgOpts);
            TweenMax.to(this.DOM.title, textDuration, textOpts);
            TweenMax.to(this.DOM.link, textDuration * 1.2, textOpts);
        });
    };

    // Handle Hero Transitions
    Slide.prototype.heroTransition = function (action, direction) {
        return new Promise((resolve, reject) => {
            const commonOpts = {
                delay: 0,
                ease: Expo.easeOut,
                onComplete: resolve,
            };

            let textDuration =
                action === 'show'
                    ? this.config.animation.duration
                    : this.config.animation.duration / 2;

            let imgOpts = Utilities.assign({}, commonOpts);
            let textOpts = Utilities.assign({}, commonOpts);
            let linkOpts = Utilities.assign({}, commonOpts);

            imgOpts.startAt =
                action === 'show'
                    ? { opacity: 1, x: direction === 'left' ? '-100%' : '100%' }
                    : { x: '0%' };
            imgOpts.x = action === 'hide' ? (direction === 'left' ? '20%' : '-20%') : '0%';

            textOpts.startAt =
                action === 'show'
                    ? { opacity: 0, x: direction === 'left' ? '-100%' : '50%' }
                    : { x: '0%' };
            textOpts.x = '0%';
            textOpts.opacity = action === 'hide' ? 0 : 1;
            textOpts.delay = action === 'hide' ? 0 : this.config.animation.duration / 3;

            linkOpts.startAt =
                action === 'show' ? { x: direction === 'left' ? '-150%' : '150%' } : { x: '0%' };
            linkOpts.x = '0%';
            linkOpts.delay = 'hide' ? 0 : this.config.animation.duration / 3;

            // Moving the content
            TweenMax.to(this.DOM.textWrap, textDuration, textOpts);
            TweenMax.to(this.DOM.link, this.config.animation.duration * 1.2, linkOpts);
            TweenMax.to(this.DOM.imgBackground, this.config.animation.duration, imgOpts);
        });
    };

    // Handle Home Transitions
    Slide.prototype.homeTransition = function (action, direction) {
        return new Promise((resolve, reject) => {
            const commonOpts = {
                delay: 0,
                ease: Expo.easeOut,
                onComplete: resolve,
            };

            let duration = 0.8;

            let backgroundOpts = Utilities.assign({}, commonOpts);
            let textOpts = Utilities.assign({},commonOpts);
            let overlayOpts = Utilities.assign({}, commonOpts);

            backgroundOpts.startAt =
                action === 'show'
                    ? { x: direction === 'left' ? '-100%' : '100%', z: '-1' }
                    : { z: '-1' };
            backgroundOpts.x = action === 'hide' ? (direction === 'left' ? '100%' : '-100%') : '0%';
            backgroundOpts.z = '-1';

            overlayOpts.startAt = action === 'show' ? { opacity: 0 } : { opacity: 1 };
            overlayOpts.opacity = action === 'hide' ? 0 : 1;
            overlayOpts.delay = action === 'hide' ? 0 : duration;

            textOpts.startAt = action === 'show' ? { opacity: 0 } : { opacity: 1 };
            textOpts.opacity = action === 'hide' ? 0 : 1.2;
            textOpts.delay = action === 'hide' ? 0 : duration;

            // Moving the content
            TweenMax.to(this.DOM.title, duration, textOpts);
            TweenMax.to(this.DOM.overlay, duration, overlayOpts);
            TweenMax.to(this.DOM.imgBackground, duration, backgroundOpts);
        });
    };

    // Handle Big Carousel transitions
    Slide.prototype.bigTransition = function (action, direction) {
        return new Promise((resolve, reject) => {
            const commonOpts = {
                delay: 0,
                onComplete: resolve,
            };

            let imgWrapOpts = Utilities.assign({}, commonOpts);
            let textWrapOpts = Utilities.assign({}, commonOpts);
            let firstImgOpts = Utilities.assign({}, commonOpts);
            let titleOpts = Utilities.assign({}, commonOpts);

            // Image Wrap behaviour
            imgWrapOpts.startAt =
                action === 'show'
                    ? { x: direction === 'left' ? '-250%' : '150%', y: this.deviceSpecific.bigImgY }
                    : {};
            imgWrapOpts.x = action === 'hide' ? (direction === 'left' ? '150%' : '-250%') : '0%';
            imgWrapOpts.y = this.deviceSpecific.bigImgY;
            imgWrapOpts.ease = this.config.animation.ease;

            // Text Wrap behaviour
            textWrapOpts.startAt =
                action === 'show' ? { opacity: 0, x: direction === 'left' ? '-100%' : '100%' } : {};
            textWrapOpts.x = action === 'hide' ? (direction === 'left' ? '100%' : '-100%') : '0%';
            textWrapOpts.opacity = action === 'hide' ? 0 : 1;
            textWrapOpts.ease = this.config.animation.ease;

            // First Image
            firstImgOpts.startAt =
                action === 'show' ? { x: direction === 'left' ? '-200%' : '200%' } : {};
            firstImgOpts.x = action === 'hide' ? (direction === 'left' ? '200%' : '-200%') : '0%';
            firstImgOpts.ease = this.config.animation.ease;

            // title
            titleOpts.startAt =
                action === 'show' ? { x: direction === 'left' ? '-10%' : '10%' } : {};
            titleOpts.x = action === 'hide' ? (direction === 'left' ? '10%' : '-10%') : '0%';
            titleOpts.ease = this.config.animation.ease;

            // Move everything
            TweenMax.to(this.DOM.imgWrap, this.config.animation.duration, imgWrapOpts);
            TweenMax.to(this.DOM.textWrap, this.config.animation.duration, textWrapOpts);
            TweenMax.to(this.DOM.firstImg, this.config.animation.duration, firstImgOpts);
            TweenMax.to(this.DOM.title, this.config.animation.duration, titleOpts);
        });
    };

    function FormLoader($page) {
        this.$page = $page;

        this.findForms($page);
    }

    FormLoader.prototype.formClass = 'js-form-load';

    FormLoader.prototype.findForms = function ($page) {
        var $forms = $page.find('.js-form-load');
        for (var i = 0; i < $forms.length; i++) {
            this.setUpForm($forms[i]);
            this.detectFormSubmit($forms[i]);
        }
    };

    FormLoader.prototype.setUpForm = function ($form) {
        var form = $form,
            successMessageText = 'Thankyou. The form was submitted successfully.';

        if (form) {
            form.addEventListener("freeform-ready", function (event) {
                event.options.errorClassBanner = "form__alert form__alert-danger form__errors";
                event.options.errorClassList = "form__help-block form__errors form__invalid-feedback";
                event.options.errorClassField = "form__is-invalid form__has-error";
                event.options.errorBannerMessage = "Something's not right. Please review the form and try submitting again.";
                event.options.successClassBanner = "form__alert form__alert-success form__form-success";
                event.options.successBannerMessage = successMessageText;

                // Override the auto Stripe styling
                form.addEventListener('freeform-stripe-styling', function (event) {
                    event.detail.base = {
                        fontSize: '16px',
                        fontFamily:
                            '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
                    };
                });
            });
        }
    };

    FormLoader.prototype.detectFormSubmit = function ($form) {
        $form.addEventListener('submit', ajaxifyForm);

        function ajaxifyForm(event) {
            event.preventDefault();

            var form = event.target;
            var data = new FormData(form);
            var successMessageText = 'Thankyou. The form was submitted successfully.';

            var method = form.getAttribute('method');
            var action = form.getAttribute('action');

            var request = new XMLHttpRequest();
            request.open(method, action ? action : window.location.href, true);
            request.setRequestHeader('Cache-Control', 'no-cache');

            // Set the Craft specific AJAX headers
            request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            request.setRequestHeader('HTTP_X_REQUESTED_WITH', 'XMLHttpRequest');

            request.onload = function () {
                if (request.status === 200) {
                    var response = JSON.parse(request.response);

                    if (response.success && response.finished) {
                        const successMessage = document.createElement('div');
                        successMessage.classList.add('form__alert', 'form__alert-success');

                        const closeBlock = document.createElement('span');
                        closeBlock.classList.add(
                            'icon',
                            'icon-hamburger',
                            'closed',
                            'js-close-message'
                        );
                        const hamburgerInner = document.createElement('div');
                        hamburgerInner.classList.add('hamburger-inner');
                        closeBlock.appendChild(hamburgerInner);

                        const paragraph = document.createElement('p');
                        paragraph.appendChild(document.createTextNode(successMessageText));

                        successMessage.appendChild(paragraph);
                        successMessage.appendChild(closeBlock);

                        globalHeader.toggleLoadingAnim('hide');
                        form.classList.remove('loading');

                        $form.insertBefore(successMessage, $form.childNodes[0]);
                        $form.querySelector('.form__submit').disabled = false;
                        form.reset();

                        // Wait a moment to add reveal
                        setTimeout(function () {
                            successMessage.classList.add('reveal');

                            // Pause to show message before auto-fade
                            setTimeout(function () {
                                // Fade out
                                successMessage.classList.remove('reveal');
                            }, 5000);
                        }, 64);
                    } else if (response.errors || response.formErrors) {
                        console.log('Field Errors', response.errors);
                        console.log('Form Errors', response.formErrors);

                        let errors = response.errors;

                        const errorBlock = document.createElement('div');
                        errorBlock.classList.add('form__alert', 'form__alert-danger');

                        const closeBlock = document.createElement('span');
                        closeBlock.classList.add(
                            'icon',
                            'icon-hamburger',
                            'closed',
                            'js-close-message'
                        );
                        const hamburgerInner = document.createElement('div');
                        hamburgerInner.classList.add('hamburger-inner');
                        closeBlock.appendChild(hamburgerInner);

                        const paragraph = document.createElement('p');
                        paragraph.appendChild(
                            document.createTextNode(
                                "Something's not right. Please review the form and try submitting again."
                            )
                        );

                        errorBlock.appendChild(paragraph);
                        errorBlock.appendChild(closeBlock);

                        for (var key in errors) {
                            // Check there is a specified key
                            if (key === 'length' || !errors.hasOwnProperty(key)) continue;

                            // Get all name inputs in case of hidden fields
                            let inputs = $form.querySelectorAll('[name="' + key + '"]');

                            for (var i = 0; i < inputs.length; ++i) {
                                inputs[i].classList.add('form__is-invalid');
                            }

                            const errorMessage = document.createElement('p');
                            errorMessage.classList.add('form__errors');
                            errorMessage.appendChild(document.createTextNode(errors[key]));

                            if (inputs[0] && inputs[0].parentNode) {
                                inputs[0].parentNode.appendChild(errorMessage);
                            }
                        }

                        globalHeader.toggleLoadingAnim('hide');
                        form.classList.remove('loading');
                        $form.querySelector('.form__submit').disabled = false;

                        $form.insertBefore(errorBlock, $form.childNodes[0]);

                        // Wait a moment to add reveal
                        setTimeout(function () {
                            errorBlock.classList.add('reveal');

                            // Pause to show message before auto-fade
                            setTimeout(function () {
                                // Fade out
                                errorBlock.classList.remove('reveal');
                            }, 8000);
                        }, 64);
                    }
                } else {
                    console.error(request);
                }
            };

            // Clear Errors
            let errorFields = $form.querySelectorAll('.form__is-invalid');
            for (var i = 0; i < errorFields.length; ++i) {
                errorFields[i].classList.remove('form__is-invalid');
            }
            let errorMessages = $form.querySelectorAll('.form__errors');
            for (var i = 0; i < errorMessages.length; ++i) {
                errorMessages[i].classList.add('hidden');
            }

            // Show loading
            globalHeader.toggleLoadingAnim('show');
            $form.classList.add('loading');

            data.append(csrfTokenName, csrfTokenValue);
            request.send(data);
        }
    };

    function ListOpener($page) {
        this.$page = $page;

        this.handleLists($page);
    }

    ListOpener.prototype.listClass = 'js-list-open';

    ListOpener.prototype.handleLists = function ($page) {
        var $links = $page.find('.js-list-open');
        for (var i = 0; i < $links.length; i++) {
            this.addEventListener($links[i]);
        }
    };

    ListOpener.prototype.addEventListener = function (control) {
        control.addEventListener(
            'click',
            function () {
                this.toggleList(control);
            }.bind(this)
        );
    };

    ListOpener.prototype.toggleList = function (control) {
        let list = control.nextElementSibling;
        list.classList.toggle('reveal');
    };

    function UrlHandler() {}

    /*
			Replace page state
		*/
    UrlHandler.prototype.replaceState = function (url, stateObj) {
        if (typeof stateObj === 'undefined') {
            stateObj = pageHandler.getCurrentPageStateObj();
        }

        window.history.replaceState(stateObj, null, url);
    };

    /*
			Push page state
		*/
    UrlHandler.prototype.pushState = function (url, stateObj) {
        if (typeof stateObj === 'undefined') {
            stateObj = pageHandler.getCurrentPageStateObj();
        }

        window.history.pushState(stateObj, null, url);
    };

    function GeneralRolloverHandler($layer) {
        this.$layer = $layer;
        this.setUpListeners();
    }

    GeneralRolloverHandler.prototype.rolloverClass = 'js-rollover';

    GeneralRolloverHandler.prototype.setUpListeners = function () {
        this.$layer
            // On clicking a trigger
            .on(
                'click',
                '.js-rollover-trigger',
                function (e) {
                    var $rollover = $(e.currentTarget).find('.js-rollover');

                    // If this overlay isn't visible
                    if (parseInt($rollover.css('opacity')) === 0) {
                        // Don't allow this to propagate
                        e.preventDefault();
                        e.stopPropagation();

                        // Hide all other overlays
                        this.hideAllOverlays($rollover);

                        $rollover.addClass('reveal');
                    }
                }.bind(this)
            )

            .on(
                'click',
                function (e) {
                    // If the click was to a video link, don't do anything
                    if ($(e.target).is('.js-play-hero-video')) {
                        return;
                    }

                    this.hideAllOverlays();
                }.bind(this)
            );
    };

    GeneralRolloverHandler.prototype.hideAllOverlays = function ($exclusion) {
        var $visibleRollovers = this.$layer.find('.js-rollover.reveal');

        if ($exclusion) {
            $visibleRollovers = $visibleRollovers.not($exclusion);
        }

        $visibleRollovers.removeClass('reveal');
    };

    function ImgLoadHandler($page) {
        this.$page = $page;

        this.searchForImages($page);
    }

    ImgLoadHandler.prototype.imgClass = 'js-img-load';

    ImgLoadHandler.prototype.searchForImages = function ($page) {
        var $containers = $page.find('.js-img-load');
        for (var i = 0; i < $containers.length; i++) {
            this.loadImage($containers[i]);
        }
    };

    ImgLoadHandler.prototype.loadImage = function ($container) {
        imagesLoaded($container, () => {
            let img = $container.getElementsByTagName('img');
            img[0].classList.add('loaded');
        });
    };

    ImgLoadHandler.prototype.onElsAppended = function ($els) {
        var $containers = $els.find('.' + this.imgClass);
        for (var i = 0; i < $containers.length; i++) {
            this.loadImage($containers[i]);
        }
    };

    function GlobalHeader($header) {
        this.$header = $header;
        this.$mainNavLinks = this.$header.find('.js-main-nav-link');
        this.$menu = this.$header.find('.js-nav-bar');
        this.$columns = this.$header.find('.menu__column');
        this.$hamburger = this.$header.find('.icon-hamburger');
        this.$loadingAnim = this.$header.find('.js-loading-anim');
        this.$siteLogo = $('.js-site-title svg');
        this.$mobileLogo = $('.js-mobile-logo svg');
        this.$search = $('.js-search');
        this.$mailChimp = $('.js-mailchimp-form-container');

        this.handleNavs();
        this.mailchimpSubmitListener(this.$mailChimp[0]);
    }

    GlobalHeader.prototype.parsePageAttributes = function ($pageAttributes, url) {
        var $el = $pageAttributes[0];

        this.setNavInvert($el.classList.contains('invert-nav'));
        this.setPageInvert($el.classList.contains('invert-page'));
        this.setCategoryPage(this.determineCategoryPage($el));
        this.setHeaderOverlay($el.classList.contains('header-overlay'));
        this.setActiveMainNavLink(this.determineActiveMainNavLink(url));
    };

    GlobalHeader.prototype.setActiveMainNavLink = function (mainNavLink) {
        var activeClass = 'active';

        this.$mainNavLinks.removeClass(activeClass);

        if (mainNavLink != null) {
            mainNavLink.addClass(activeClass);
        }
    };

    GlobalHeader.prototype.setHomePage = function (isHome) {
        $mobileLogo[0].classList[isHome ? 'add' : 'remove']('hidden');
        $nav[0].classList[isHome ? 'add' : 'remove']('home--ready');
        $siteLogo[0].classList[isHome ? 'add' : 'remove']('home');
        $mobileLogo[0].classList[isHome ? 'add' : 'remove']('hidden');

        if (isHome) {
            setTimeout(function () {
                $('.js-home-carousel').addClass('home');
                $nav[0].classList.add('home');
            }, 800);

            // Activate scroll down option
            Utilities.scrollToTarget('.js-scroll-down', 0);
        } else {
            $nav[0].classList.remove('home');
        }
    };

    GlobalHeader.prototype.setNavInvert = function (invertNav) {
        $nav[0].classList[invertNav ? 'add' : 'remove']('nav--inverted');
        this.$siteLogo[0].style.fill = invertNav ? '#ffffff' : '#132436';
    };

    GlobalHeader.prototype.setPageInvert = function (invertPage) {
        $body[0].classList[invertPage ? 'add' : 'remove']('invert-page');
        this.$mobileLogo[0].style.fill = invertPage ? '#132436' : '#ffffff';
    };

    GlobalHeader.prototype.setCategoryPage = function (category) {
        var isCategory;
        if (category != null) {
            $categoryName.html(category);
            isCategory = true;
        } else {
            isCategory = false;
        }
        $liveCategory[0].classList[isCategory ? 'add' : 'remove']('active');
    };

    GlobalHeader.prototype.setHeaderOverlay = function (isOverlay) {
        $pseudoHeader[0].classList[isOverlay ? 'add' : 'remove']('hide-desktop');
    };

    GlobalHeader.prototype.determineCategoryPage = function ($el) {
        if ($el.classList.contains('category-page')) {
            return $el.dataset.categorypage;
        } else {
            return null;
        }
    };

    GlobalHeader.prototype.determineActiveMainNavLink = function (url) {
        for (var i = 0; i < this.mainNavUrls.length; i++) {
            var slug = new RegExp(this.mainNavUrls[i]);

            if (slug.test(url)) {
                return this.getMainNavLink('js-' + slug + '-link');
            }
        }
    };

    GlobalHeader.prototype.getMainNavLink = function (className) {
        let objectName = String(className).replace(/\//g, '');
        return globalHeader.$mainNavLinks.filter('.' + objectName);
    };

    GlobalHeader.prototype.determineMobile = function () {
        if (window.matchMedia('(max-width: 919px)').matches) {
            return true;
        }
    };

    GlobalHeader.prototype.toggleLoadingAnim = function (action) {
        this.$loadingAnim[0].classList[action == 'show' ? 'add' : 'remove']('loading');
    };

    GlobalHeader.prototype.handleNavs = function () {
        this.mainNavUrls = [];

        for (var i = 0; i < this.$mainNavLinks.length; i++) {
            let slug = this.$mainNavLinks[i].getAttribute('data-title');
            this.mainNavUrls.push(slug);
        }

        $body
            .on(
                'click',
                '.js-menu-trigger',
                function () {
                    this.toggleMenu();
                }.bind(this)
            )
            .on(
                'click',
                '.js-mailchimp-open',
                function () {
                    this.openMailchimp(this.$mailChimp[0]);
                }.bind(this)
            )
            .on(
                'click',
                '.js-mailchimp-close',
                function () {
                    this.closeMailchimp();
                }.bind(this)
            )
            .on(
                'click',
                '.js-search-open',
                function () {
                    this.openSearch();
                }.bind(this)
            )
            .on(
                'click',
                '.js-search-close',
                function () {
                    this.closeSearch();
                }.bind(this)
            )
            // We need to close this also on clicking an anchor, as each page is app handled
            .on(
                'click',
                'a',
                function () {
                    this.closeMenu();
                }.bind(this)
            )
            .on('click', '.js-close-message', function (e) {
                let elem = e.target;
                elem.parentNode.classList.remove('reveal');
            });
    };

    GlobalHeader.prototype.toggleMenu = function () {
        globalHeader.closeMailchimp();
        this.$menu[0].classList.toggle('open');
        this.$hamburger[0].classList.toggle('closed');
    };

    GlobalHeader.prototype.closeMenu = function () {
        this.$menu[0].classList.remove('open');
        this.$hamburger[0].classList.remove('closed');
    };

    GlobalHeader.prototype.openSearch = function () {
        globalHeader.closeMailchimp();
        this.$search[0].classList.add('show');
    };

    GlobalHeader.prototype.closeSearch = function () {
        this.$search[0].classList.remove('show');
    };

    GlobalHeader.prototype.openMailchimp = function ($modal) {
        this.$modal = $modal;
        if (!$modal.classList.contains('show')) {
            $modal.classList.add('show');
            this.addClickListener($modal);
        }
    };

    GlobalHeader.prototype.addClickListener = function ($modal) {
        setTimeout(function () {
            document.addEventListener('click', globalHeader.outsideClickListener);
        }, 50);
    };

    GlobalHeader.prototype.removeClickListener = function () {
        document.removeEventListener('click', globalHeader.outsideClickListener);
    };

    GlobalHeader.prototype.outsideClickListener = function (event) {
        if (!globalHeader.$modal.contains(event.target)) {
            globalHeader.closeMailchimp();
        }
    };

    GlobalHeader.prototype.closeMailchimp = function () {
        this.$mailChimp[0].classList.remove('show');
        globalHeader.removeClickListener();
    };

    GlobalHeader.prototype.mailchimpSubmitListener = function ($form) {
        const mailchimpForm = $form.querySelector('.js-mailchimp-form'),
            formSubmit = mailchimpForm.querySelector('.js-form-submit'),
            responseMessage = document.querySelector('.js-mailchimp-form-response');

        // On form Submission send over AJAX request
        if (window.fetch) {
            mailchimpForm.addEventListener('submit', (e) => {
                e.preventDefault();

                // Send form data for processing
                const formData = new FormData(mailchimpForm),
                    serializedData = Array.from(formData, (x) =>
                        x.map(encodeURIComponent).join('=')
                    ).join('&');

                // Show loader
                $form.classList.add('loading');

                responseMessage.classList.remove('show');

                // Make post request
                fetch('/', {
                    method: 'POST',
                    body: new URLSearchParams(serializedData),
                    headers: {
                        Accept: 'application/json',
                    },
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        // Hide loader
                        $form.classList.remove('loading');

                        if (data.success) {
                            responseMessage.classList.remove('error');
                        } else {
                            responseMessage.classList.add('error');
                        }

                        responseMessage.textContent = data.message;

                        responseMessage.classList.add('show');

                        if (data.success) {
                            setTimeout(function () {
                                globalHeader.closeMailchimp();
                            }, 5000);
                        }
                    });
            });
        }
    };

    function Page($page, url) {
        this.pageId = pageHandler.assignPageId();
        this.$el = $page;
        this.handlers = [];

        this.stateObj = {
            time: pageHandler.pageLoadTime,
            pageId: this.pageId,
        };

        if (typeof url !== 'undefined') {
            this.url = url;
        }

        // Add this layer to our array
        pageHandler.pages.push(this);
    }

    /*
	Calls a method for each handler, if it is present
*/
    Page.prototype.runHandlersFn = function (fn, els, callingObj) {
        // For each handler for this layer
        for (var i = 0; i < this.handlers.length; i++) {
            // If this handler has this method
            if (typeof this.handlers[i][fn] === 'function') {
                // Call it
                this.handlers[i][fn](els, callingObj);
            }
        }
    };

    var $win = $(window),
        $body = $('body'),
        siteUrl = document.location.origin,
        $mobileLogo = $('.js-mobile-logo'),
        $nav = $('.js-nav'),
        $siteLogo = $('.js-site-title'),
        $searchForm = $('.js-search-form'),
        $liveCategory = $('.js-live-category'),
        $categoryName = $('.js-category-name'),
        $pseudoHeader = $('.js-pseudo-header'),
        urlHandler = new UrlHandler(),
        $carousels,
        infScrollHandler,
        easersHandler,
        easersLive,
        dragging,
        winH,
        winW,
        winScrollT,
        onScrollGeneralFns = [updateWinScrollT],
        onResizeGeneralFns = [updateWinW, updateWinH],
        canHover = !matchMedia('(hover: none)').matches,
        historySupport = !!(window.history && history.pushState),
        browserDetection = (function detectBrowser() {
            var browser = {};

            if (navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
                browser.iOS = {};
            }

            if (
                !browser.iOS &&
                (/constructor/i.test(window.HTMLElement) ||
                    (function (p) {
                        return p.toString() === '[object SafariRemoteNotification]';
                    })(!window['safari'] || safari.pushNotification))
            ) {
                // jshint ignore:line
                browser.safariDesktop = {};
            }

            if (/*@cc_on!@*/ false || !!document.documentMode) {
                browser.ie = {};
            }

            if (navigator.userAgent.indexOf('Windows Phone') > -1) {
                browser.windowsPhone = {};
            }

            return browser;
        })();

    if (browserDetection.iOS) {
        $body.removeClass('hovers').addClass('iOS');
    }

    function runGeneralFns(fns) {
        fns.forEach(function (fn) {
            fn();
        });
    }

    function updateWinScrollT(val) {
        if (!val) {
            val = $win.scrollTop();
        }

        winScrollT = val;
    }

    function updateWinH() {
        winH = $win.height();
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    function updateWinW() {
        winW = $win.width();
    }

    /*
	Returns a timeout for a setTimeout which ensures we are at least 'atLeast' beyond
	the 'startTime'
	*/
    function getAtLeastTimeout(atLeast, startTime) {
        // The first parameter to Math.max figures out the time left between
        // the click and the end of the 'atLeast'.
        return Math.max(atLeast - (Date.now() - startTime), 0);
    }

    function initGeneralListeners() {
        $win.on('scroll', function () {
            runGeneralFns(onScrollGeneralFns);
            pageHandler.onScroll();
        });

        $win.on('resize', function () {
            runGeneralFns(onResizeGeneralFns);
            pageHandler.onResize();
        });

        $body.on('click', '[href]', newPageLoad);

        $searchForm[0].addEventListener('submit', function (e) {
            e.preventDefault();

            var searchTerm = document.getElementById('search').value,
                href = siteUrl + '/search/results?&q=' + searchTerm;

            pageHandler.pageReloader(href);
            globalHeader.closeSearch();
        });

        $win.on('popstate', function (e) {
            // If this is running on page load (happens in certain browsers), don't allow it
            if (!e.originalEvent.state) {
                return;
            }

            // If the state we are returning to is from a previous page load
            if (e.originalEvent.state.time !== pageHandler.pageLoadTime) {
                // Reload this page as the popstate change won't work properly
                window.location.reload();

                // Don't go on
                return;
            }

            if (pageHandler.iOSFunniness(e)) {
                return;
            }

            // If we have popped back
            if (pageHandler.isBack(e)) {
                // Close the last layer in use
                pageHandler.closeCurrentPage(location.toString());
                // If we have popped forward
            } else {
                pageHandler.pageReloader(location.toString());
            }
        });

        // Reset dragging flag when a new touchstart is triggered
        document.addEventListener(
            'touchstart',
            () => {
                dragging = false;
            },
            { passive: true }
        );

        // Monitor for if the user is scrolling (touchmove) the body
        document.addEventListener(
            'touchmove',
            () => {
                dragging = true;
            },
            { passive: true }
        );
    }

    function sizeIframes() {
        let iframes = document.querySelectorAll('.text-area figure:not(.image-section) iframe');

        iframes.forEach(iframe => {
            if (!iframe.getAttribute('height')) {
                let newContainer = document.createElement('div');
                newContainer.classList.add('video-sizing')
                
                iframe.parentNode.insertBefore(newContainer, iframe);

                newContainer.appendChild(iframe);
            }
        });
    }

    // Handle the page layers for AJAX loading
    var pageHandler = {
        $pageLoader: $('.js-pageloader'),

        $pageAttributes: $('.js-page-attributes'),

        prevPageAttributes: [],

        nextPageId: 0,

        pages: [],

        waitForImages: false,

        // Page load time
        pageLoadTime: Date.now(),

        init: function () {
            var $page = this.$pageLoader.find('.js-page-content'),
                url = window.location.href,
                page = new Page($page);

            this.$pageTemplate = this.createPageTemplate($page);

            this.preemptNextPage();

            // Get the transition durations
            this.openingTransitionT = this.getTransitionT($page);
            this.closingTransitionT = this.getTransitionT(
                this.$pageLoader.find('.js-page-content').last()
            );

            this.onPageLoad(page);

            globalHeader.setActiveMainNavLink(globalHeader.determineActiveMainNavLink(url));

            this.replaceState(page);

            this.revealPage(page);

            this.addToFlow(page);

            setTimeout(function () {
                globalHeader.setHomePage(pageHandler.$pageAttributes[0].classList.contains('home'));
            }, 800);
        },

        /*
			Gets the id to assign to this next layer
		*/
        assignPageId: function () {
            var id = this.nextPageId;

            // Increment for next time
            this.nextPageId++;

            return id;
        },

        onPageLoad: function (page) {
            this.createHandlers(page);
        },

        createHandlers: function (page) {
            var handlers = [],
                $rollovers = page.$el.find('.' + GeneralRolloverHandler.prototype.rolloverClass),
                $carousels = page.$el.find(
                    '.' + InlineCarouselHandler.prototype.inlineCarouselClass
                ),
                $heroCarousel = page.$el.find(
                    '.' + InlineCarouselHandler.prototype.heroCarouselClass
                ),
                $infScrollContainer = page.$el.find(
                    '.' + InfScrollHandler.prototype.containerClass
                ),
                $easers = page.$el.find('.' + EasersHandler.prototype.easerClass),
                $listOpener = page.$el.find('.' + ListOpener.prototype.listClass),
                $formLoader = page.$el.find('.' + FormLoader.prototype.formClass),
                $imgLoader = page.$el.find('.' + ImgLoadHandler.prototype.imgClass);

            if ($carousels.length) {
                handlers.push(new InlineCarouselHandler(page.$el));
            }

            if ($heroCarousel.length) {
                pageHandler.waitForImages = true;
            }

            if ($formLoader.length) {
                handlers.push(new FormLoader(page.$el));
            }

            if ($listOpener.length) {
                handlers.push(new ListOpener(page.$el));
            }

            if ($rollovers.length) {
                handlers.push(new GeneralRolloverHandler(page.$el));
            }

            if ($imgLoader.length) {
                handlers.push(new ImgLoadHandler(page.$el));
            }

            sizeIframes();

            if ($easers.length) {
                handlers.push(new EasersHandler($easers, page.$el));
                easersLive = true;
            } else {
                easersLive = false;
            }

            if ($infScrollContainer.length) {
                handlers.push(new InfScrollHandler($infScrollContainer));
            }

            // For each of them
            for (var i = 0; i < handlers.length; i++) {
                // Assign them to this layer's handlers array
                page.handlers.push(handlers[i]);
            }
        },

        /*
		Runs any 'onBeforeShow' fns in the layer's handlers, for use on e.g.
		layer load or returning to a layer
	*/
        runOnBeforeShowFns: function (page) {
            page.runHandlersFn('onBeforeShow');
        },

        pageReloader: function (href, $link, disablePushState) {
            var currentPage = this.getCurrentPage(),
                // Create a new page object
                openingPage = new Page(this.$pageLoader.find('.js-page-content').last(), href);

            currentPage.runHandlersFn('onBeforeSuperseded');

            this.pushState(openingPage);

            // Assign the current layer's current scroll top value to it for later use
            currentPage.prevScrollT = winScrollT;

            pageHandler.disablePage(currentPage);

            globalHeader.toggleLoadingAnim('show');

            this.revealPage(openingPage);

            // Stash page attributes element for potential layer close
            this.prevPageAttributes.push(this.$pageAttributes);

            this.loadPageContent(openingPage, currentPage, disablePushState, href);

            this.preemptNextPage();
        },

        loadPageContent: function (openingPage, currentPage, disablePushState, href) {
            var clickTime = Date.now(),
                postData = {};

            postData[csrfTokenName] = csrfTokenValue;

            $.post(openingPage.url, postData, function (response) {
                setTimeout(function () {
                    if (!disablePushState) {
                        // Update the browser's address bar
                        // window.history.pushState({ url: href }, null, href);
                    }

                    // Get the <title> tag
                    var title = $(response).filter('title').text();

                    // Check for doubled-name bug
                    if (title.indexOf('ChristianityChristianity') !== false) {
                        title = title.replace('Christianity - Christianity', '');
                    }

                    // Apply title
                    document.title = title;
                    openingPage.title = title;

                    // Get the new body class
                    var parser = new DOMParser(),
                        doc = parser.parseFromString(response, 'text/html'),
                        docClass = doc.body.getAttribute('class');

                    parser = doc = null;

                    // Update the .projects DOM and Body Class
                    document.body.className = docClass;

                    var $html = $('<div />').append(response),
                        $pageClipboard = openingPage.$el.children('.js-layer-clipboard'),
                        $pageToLoad = $html.find('.js-layer-clipboard').html();

                    pageHandler.$pageAttributes = $html.find('.js-page-attributes');

                    $pageClipboard.html($pageToLoad);

                    globalHeader.parsePageAttributes(pageHandler.$pageAttributes, href);

                    // If scroll bars are currently in use
                    if ($body.innerHeight() > winH) {
                        // Force them here so they are not removed during the below timeout
                        // causing an awkward jump in browsers which use them
                        $body.css('overflow', 'scroll');
                    }

                    pageHandler.hidePage(currentPage);

                    setTimeout(function () {
                        pageHandler.addToFlow(openingPage);

                        // Reset this
                        $body.css('overflow', '');

                        // Reset the scroll top to the top
                        $win.scrollTop(0);

                        // Manually update this here as the on('scroll') handler will not fire
                        // until the end of this flow, causing the initial scrollTop value
                        // to be incorrectly greater than 0
                        updateWinScrollT(0);

                        pageHandler.onPageLoad(openingPage);

                        setTimeout(function () {
                            pageHandler.revealClipboardContents($pageClipboard);

                            globalHeader.toggleLoadingAnim('hide');

                            globalHeader.setHomePage(
                                pageHandler.$pageAttributes[0].classList.contains('home')
                            );
                        }, 4);

                        tracking.set(openingPage.title);

                        tracking.sendPageview();

                        // Trigger picture resize/fill on IE
                        window.picturefill();
                    }, 16);
                }, getAtLeastTimeout(pageHandler.openingTransitionT + 64, clickTime));
            });
        },

        closeCurrentPage: function (href) {
            var currentScrollTop = winScrollT,
                closingPage = this.getCurrentPage(),
                previousPage = this.pages[this.pages.length - 2];

            closingPage.runHandlersFn('onBeforeClose');

            // pageHandler.disablePage(closingPage);

            // globalHeader.toggleLoadingAnim('show');

            this.removeFromFlow(closingPage, currentScrollTop);

            this.unhidePage(previousPage);

            this.resetScrollTop(previousPage);

            this.runOnBeforeShowFns(previousPage);

            setTimeout(function (href) {
                window.requestAnimationFrame(function (href) {
                    pageHandler.unrevealPage(closingPage);

                    // globalHeader.toggleLoadingAnim('hide');
                    pageHandler.enablePage(previousPage);
                    globalHeader.parsePageAttributes(
                        pageHandler.prevPageAttributes[pageHandler.prevPageAttributes.length - 1],
                        href
                    );
                    globalHeader.setHomePage(
                        pageHandler.prevPageAttributes[
                            pageHandler.prevPageAttributes.length - 1
                        ][0].classList.contains('home')
                    );
                    // After the transition has finished
                    setTimeout(function () {
                        // Remove this no-longer needed layer from the DOM
                        pageHandler.removePage(closingPage);

                        // Remove the relevant layer object from the array
                        pageHandler.pages.pop();
                        pageHandler.prevPageAttributes.pop();
                    }, pageHandler.closingTransitionT);
                });
            }, 64);

            tracking.set(previousPage.title);
        },

        /*
			Update page state
		*/
        pushState: function (page) {
            urlHandler.pushState(page.url, page.stateObj);
        },

        /*
			Update page state
		*/
        replaceState: function (page) {
            // IE actually appends 'undefined' to the location if layer.url is undefined
            var url = page.url ? page.url : null;

            urlHandler.replaceState(url, page.stateObj);
        },

        disablePage: function (page) {
            page.$el.addClass('disabled');
        },

        enablePage: function (page) {
            page.$el.removeClass('disabled');
        },

        hidePage: function (page) {
            page.$el.addClass('hide');
        },

        unhidePage: function (page) {
            page.$el.removeClass('hide');
        },

        revealPage: function (page) {
            page.$el.addClass('reveal');
        },

        unrevealPage: function (page) {
            page.$el.addClass('reveal');
        },

        /*
			Reveals the contents of the layer
		*/
        revealClipboardContents: function (clipboard) {
            // Reveal the contents
            clipboard.addClass('reveal');
        },

        /*
			Adds the layer to the flow
		*/
        addToFlow: function (page) {
            // Add this layer to the flow
            page.$el.addClass('add-to-flow');
        },

        /*
			Removes the layer from the flow
		*/
        removeFromFlow: function (page, scrollTop) {
            // Remove this layer from the flow
            page.$el.removeClass('add-to-flow');
            // Set the current layer's scroll top (now that we have changed its
            // position value) so it doesn't jump to the top
            page.$el.scrollTop(scrollTop);
        },

        /*
		Sets the scroll position of the body to this el's stored value
		and updates the 'global' variable
		*/
        resetScrollTop: function (layer) {
            // Set the scroll position of the body to this layer's stored value
            $win.scrollTop(layer.prevScrollT);

            // Manually update this now as more of this close layer function will
            // fire before the on('scroll') trigger will cause this to update
            updateWinScrollT(layer.prevScrollT);
        },
        /*
			Removes the layer from the DOM
		*/
        removePage: function (page) {
            // Remove this layer from the DOM
            page.$el.remove();
        },

        /*
		Finds the images in hero Carousel for pre-loading
		*/
        findCarouselImages: function ($carousel) {},

        /*
			See https://basecamp.com/1769472/projects/11385033/todos/281509074#comment_476999794
		*/
        iOSFunniness: function (e) {
            var stateId = e.originalEvent.state.pageId;
            var currentPageId = this.getCurrentPage().pageId;

            return stateId === 0 && currentPageId === 0;
        },

        /*
			Checks if a popstate event represents window.history.back()
		*/
        isBack: function (e) {
            var stateId = e.originalEvent.state.pageId;
            var currentPageId = this.getCurrentPage().pageId;

            // Returns true if the state we are going to has no id (is the base layer)
            // or it is less than the current layer
            return typeof stateId === 'undefined' || stateId < currentPageId;
        },

        /*
			Returns the current layer's state object for use in external functions
			which update the URL but need to maintain the state
		*/
        getCurrentPageStateObj: function () {
            return this.getCurrentPage().stateObj;
        },

        /*
			Creates the template to be used for new layers
		*/
        createPageTemplate: function ($page) {
            var $template = $page.clone();

            $template.find('.js-layer-clipboard').empty();

            return $template;
        },

        preemptNextPage: function () {
            this.$pageLoader.append(this.$pageTemplate.clone());
        },

        /*
			Returns the transition duration as a float in ms.
			If transition duration is not a thing, set to 0
		*/
        getTransitionT: function (el) {
            var transitionDuration = el.css('transition-duration');

            if (transitionDuration) {
                return parseFloat(transitionDuration.split(',')[0]) * 1000;
            }

            return 0;
        },

        /*
		Returns the current layer object
		*/
        getCurrentPage: function () {
            return this.pages[this.pages.length - 1];
        },

        /*
			Handles events / inits for layers when first loaded
		*/
        onPageLoad: function (page) {
            // Build all the relevant handlers
            this.createHandlers(page);

            // Run any 'onBeforeShow' functions
            this.runOnBeforeShowFns(page);
        },

        /*
			External handler for scroll and resize.
		*/
        onScroll: function () {
            this.getCurrentPage().runHandlersFn('onScroll');
        },

        onResize: function () {
            this.getCurrentPage().runHandlersFn('onResize');
        },

        onElsAppended: function (newEls, callingObj) {
            this.getCurrentPage().runHandlersFn('onElsAppended', newEls, callingObj);
        },
    };

    var tracking = {
        /*
			Go go go!
		*/
        init: function () {
            // For live previews and environments other than production,
            // Google Analytics is not loaded to prevent rogue tracking
            this.noAnalytics = typeof ga == 'undefined';
        },

        /*
			'Class' for the field objects to be passed to Google Analytics
		*/
        FieldsObject: function (type, opts) {
            this.hitType = type;

            // For each property passed
            for (var prop in opts) {
                // If the value is usable
                if (opts[prop]) {
                    // Set it
                    this[prop] = opts[prop];
                }
            }
        },

        /*
			Updates the page details which Google Analytics thinks we are on.
			See: https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications#tracking_virtual_pageviews
		*/
        set: function (title) {
            // If we haven't got ga, don't carry on!
            if (this.noAnalytics) {
                return;
            }

            ga('set', {
                title: title,
                page: location.pathname,
            });
        },

        /*
			Creates a Google Analytics pageview
		*/
        sendPageview: function () {
            var fieldsObject = new this.FieldsObject('pageview');

            this.send(fieldsObject);
        },

        /*
			Creates a Google Analytics Event
		*/
        sendEvent: function (category, action, label, value, nonInteraction) {
            var fieldsObject = new this.FieldsObject('event', {
                eventCategory: category,
                eventAction: action,
                eventLabel: label,
                eventValue: value,
            });

            if (nonInteraction === true) {
                fieldsObject.nonInteraction = true;
            }

            this.send(fieldsObject);
        },

        /*
			Sends the actual track to GA
		*/
        send: function (fieldsObject) {
            // If we haven't got ga, don't carry on!
            if (this.noAnalytics) {
                return;
            }

            ga('send', fieldsObject);
        },
    };

    var globalHeader = new GlobalHeader($('.js-header'));

    updateWinScrollT();
    updateWinH();
    updateWinW();

    tracking.init();

    initGeneralListeners();

    pageHandler.init();

    Cookies.init();
});
